import React, { useEffect, useState } from 'react';
import { File, Home, PlusCircle } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createUnPublicFile, deleteUnPublicFile, getAllFolders, getAllProducts, getAllUnPublicFileGroups, getAllUnPublicFiles, getUnPublicFile, updateUnPublicFile } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Select, Switch } from 'antd';
import { url2 } from '../../api';

const UnPublicFilePage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [fileGroups, setFileGroups] = useState([])
    const [products, setProducts] = useState([])
    const [folders, setFolders] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [formData, setFormData] = useState({
        file: null,
        filePath: "",
        fileNumber: "",
        expirationDate: "",
        fiel1: "",
        field1EN: "",
        field2: "",
        field2EN: "",
        field3: "",
        field3EN: "",
        unPublicFileGroupId: null,
        refNo: "",
        lotNo: "",
        isUnPublic: true,
        productId: null,
        folderId: null,
    })

    const clearForm = () => {
        setFormData({ file: null, filePath: "", fileNumber: "", expirationDate: "", fiel1: "", field1EN: "", field2: "", field2EN: "", field3: "", field3EN: "", unPublicFileGroupId: null, refNo: "", lotNo: "", isUnPublic: true, productId: null, folderId: null })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const images = await getAllUnPublicFiles("");
        const group = await getAllUnPublicFileGroups();
        const product = await getAllProducts("");
        const folders = await getAllFolders();

        if (images.message === "Success")
            setData(images.result);

        if (group.message === "Success") {
            var newlist = []
            for (var i = 0; i < group.result.length; i++) {
                if (group.result[i] !== undefined)
                    newlist.push({ value: group.result[i].unPublicFileGroupID, label: group.result[i].title });
            }
            setFileGroups(newlist);
        }
        if (product.message === "Success") {
            var newlist = []
            for (var i = 0; i < product.result.length; i++) {
                if (product.result[i] !== undefined)
                    newlist.push({ value: product.result[i].productId, label: product.result[i].productName });
            }
            setProducts(newlist);
        }
        if (folders.message === "Success") {
            var newlist = []
            for (var i = 0; i < folders.result.length; i++) {
                if (folders.result[i] !== undefined)
                    newlist.push({ value: folders.result[i].folderId, label: folders.result[i].folderName });
            }
            setFolders(newlist);
        }
        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getUnPublicFile(id);
        if (data.result) {
            setFormData({ fileNumber: data.result.fileNumber, expirationDate: data.result.expirationDate, fiel1: data.result.additionalField1, field1EN: data.result.additionalField1EN, field2: data.result.additionalField2, field2EN: data.result.additionalField2EN, field3: data.result.additionalField3, field3EN: data.result.additionalField3EN, unPublicFileGroupId: data.result.unPublicFileGroupID, refNo: data.result.referanceNo, lotNo: data.result.lotNo, isUnPublic: data.result.isUnPublic, productId: data.result.productId, folderId: data.result.folderId, filePath: `${url2}${data.result.fileFullPath}`, })
        }
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateUnPublicFile(selectedData, formData.file, formData.fileNumber, formData.expirationDate, formData.fiel1, formData.field1EN, formData.field2, formData.field2EN, formData.field3, formData.field3EN, formData.refNo, formData.isUnPublic, formData.lotNo, formData.productId, formData.folderId)
        } else {
            res = await createUnPublicFile(formData.file, formData.fileNumber, formData.expirationDate, formData.fiel1, formData.field1EN, formData.field2, formData.field2EN, formData.field3, formData.field3EN, formData.refNo, formData.isUnPublic, formData.lotNo, formData.productId, formData.folderId)
        }
        if (res.message === "Success")
            toast.success("Gizli dosya eklendi.")
        else
            toast.error("Gizli dosya eklenirken bir sorun oluştu!")
        clearForm()
        getDatas()
        setModal(false)
    }

    const deleteData = async (id) => {
        const image = await deleteUnPublicFile(id);
        if (image.message === "Success")
            toast.success("Gizli dosya grubu içeriği silindi.")
        else
            toast.error("Gizli dosya grubu içeriği silinirken bir sorun oluştu!")
        getDatas()
    }

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div className='w-100 d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Gizli Dosya Listesi</h4></div>
                                <div><Button className='bg-white border-success text-success' onClick={() => { clearForm(); setModal(!modal) }}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Ekle</Button></div>
                            </CardHeader>
                            <CardBody>
                                <Row className='fileCards'>
                                    {
                                        data.map((item, index) => {
                                            return (
                                                loading ? <Col key={index} md={6} className='mb-3'>
                                                    <Card className='position-relative'>
                                                        <div style={{ position: 'absolute', top: -15, left: -15, width: 36, height: 36, borderRadius: 18, backgroundColor: '#17a2b8', color: 'white', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            {item.unPublicFileID}
                                                        </div>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <Popconfirm
                                                                                title="Bu gizli dosyayı silmek istediğinizden emin misiniz?"
                                                                                onConfirm={() => deleteData(item.unPublicFileID)}
                                                                                okText="Evet"
                                                                                cancelText="Hayır"
                                                                            >
                                                                                <div style={{ cursor: 'pointer' }} className='border rounded mb-3 text-center border-danger text-danger'>Sil</div>
                                                                            </Popconfirm>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div style={{ cursor: 'pointer' }} onClick={() => { getData(item.unPublicFileID); setModal(true); }} className='border rounded mb-3 text-center border-warning text-warning'>Güncelle</div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={4} className='d-flex justify-content-center align-items-center'>
                                                                    <File size={40} />
                                                                </Col>
                                                                <Col md={8}>
                                                                    <div className='mb-3'>
                                                                        <div><h6 className='text-secondary'>Ref No</h6></div>
                                                                        <div style={{ marginTop: -7 }}>{item.referanceNo}</div>
                                                                    </div>
                                                                    <div className='mb-3'>
                                                                        <div><h6 className='text-secondary'>Lot No</h6></div>
                                                                        <div style={{ marginTop: -7 }}>{item.lotNo}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                                </div>
                                            )
                                        })
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setModal(!modal)}>
                    <div><h4>{selectedData ? "Medya Güncelle" : "Medya Ekle"}</h4></div>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6} className='mb-3 d-flex align-items-center'>
                            <div>
                                <span>Gizli dosya</span>
                                <Input
                                    type='file'
                                    onChange={e => {
                                        setFormData(a => ({ ...a, file: e.target.files[0] }))
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Dosya Numarası</span>
                            <Input
                                type='input'
                                value={formData.fileNumber}
                                onChange={e => setFormData(a => ({ ...a, fileNumber: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Geçerlilik Tarihi</span>
                            <Input
                                type='date'
                                value={formData.expirationDate}
                                onChange={e => setFormData(a => ({ ...a, expirationDate: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ek Alan 1</span>
                            <Input
                                type='input'
                                value={formData.fiel1}
                                onChange={e => setFormData(a => ({ ...a, fiel1: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ek Alan 1 (EN)</span>
                            <Input
                                type='input'
                                value={formData.field1EN}
                                onChange={e => setFormData(a => ({ ...a, field1EN: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ek Alan 2</span>
                            <Input
                                type='input'
                                value={formData.field2}
                                onChange={e => setFormData(a => ({ ...a, field2: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ek Alan 2 (EN)</span>
                            <Input
                                type='input'
                                value={formData.field2EN}
                                onChange={e => setFormData(a => ({ ...a, field2EN: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ek Alan 3</span>
                            <Input
                                type='input'
                                value={formData.field3}
                                onChange={e => setFormData(a => ({ ...a, field3: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ek Alan 3(EN)</span>
                            <Input
                                type='input'
                                value={formData.field3EN}
                                onChange={e => setFormData(a => ({ ...a, field3EN: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Gizli Dosya Grubu</span>
                            <Select
                                options={fileGroups}
                                className='w-100'
                                style={{ height: 35 }}
                                value={formData.unPublicFileGroupId}
                                onChange={e => setFormData({ ...formData, unPublicFileGroupId: e })}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ref No</span>
                            <Input
                                type='input'
                                value={formData.refNo}
                                onChange={e => setFormData(a => ({ ...a, refNo: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Lot No</span>
                            <Input
                                type='input'
                                value={formData.lotNo}
                                onChange={e => setFormData(a => ({ ...a, lotNo: e.target.value }))}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Gizli Dosya</span>
                            <div className='mt-2'>
                                <Switch
                                    checked={formData.isUnPublic}
                                    onChange={e => setFormData(a => ({ ...a, isUnPublic: e }))}
                                />
                            </div>
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Ürün</span>
                            <Select
                                options={products}
                                className='w-100'
                                style={{ height: 35 }}
                                value={formData.productId}
                                onChange={e => setFormData({ ...formData, productId: e })}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <span>Dosya</span>
                            <Select
                                options={folders}
                                className='w-100'
                                style={{ height: 35 }}
                                value={formData.folderId}
                                onChange={e => setFormData({ ...formData, folderId: e })}
                            />
                        </Col>
                    </Row>
                    <Button color={selectedData ? 'warning' : 'success'} className='mt-4 w-100' onClick={() => addData()}>{selectedData ? "Güncelle" : "Ekle"}</Button>
                </ModalBody>
            </Modal>
        </motion.div>
    );
};

export default UnPublicFilePage;
