import React, { useEffect, useState } from 'react';
import { Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Select, Button as Button2 } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createBlog, deleteBlog, getBlog, getAllImageGroups, updateBlog, getAllBlogs } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';

const BlogPage = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [imageGroups, setImageGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        titleEn: "",
        desc: "",
        descEn: "",
        url: "",
        urlEn: "",
        content: "",
        contentEn: "",
        mediaID: null,
        imageGroupID: null,
    });

    const clearForm = () => {
        setFormData({ title: "", titleEn: "", desc: "", descEn: "", url: "", urlEn: "", content: "", contentEn: "", mediaID: null, imageGroupID: null })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const imageGroups = await getAllImageGroups();
        const blogs = await getAllBlogs("");
        if (blogs.message === "Success")
            setData(blogs.result);

        if (imageGroups.message === "Success") {
            var newlist = []
            for (var i = 0; i < imageGroups.result.length; i++) {
                if (imageGroups.result[i] !== undefined)
                    newlist.push({ value: imageGroups.result[i].imageGroupID, label: imageGroups.result[i].title });
            }
            setImageGroups(newlist);
            setTimeout(() => {
                setLoading(true)
            }, 1000);
        }
    };

    const getData = async (id) => {
        const blog = await getBlog(id);
        setSelectedData(id)
        setFormData({ title: blog.result.title, titleEn: blog.result.titleEN, desc: blog.result.shortDescription, descEn: blog.result.shortDescriptionEN, url: blog.result.seoUrl, urlEn: blog.result.seoUrlEN, content: blog.result.content, contentEn: blog.result.contentEN, imageGroupID: blog.result.imageGroupID, mediaID: blog.result.mediaId })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateBlog(selectedData, formData.title, formData.titleEn, formData.desc, formData.descEn, formData.url, formData.urlEn, formData.content, formData.contentEn, formData.imageGroupID, formData.mediaID);
        } else {
            res = await createBlog(formData.title, formData.titleEn, formData.desc, formData.descEn, formData.url, formData.urlEn, formData.content, formData.contentEn, formData.imageGroupID, formData.mediaID);
        }
        if (res.message === "Success")
            toast.success("Blog içeriği eklendi.")
        else
            toast.error("Blog içeriği eklenirken bir sorun oluştu!")
        getDatas()
        clearForm()
    }

    const deleteData = async (id) => {
        const blog = await deleteBlog(id);
        if (blog.message === "Success")
            toast.success("Blog içeriği silindi.")
        else
            toast.error("Blog içeriği silinirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "ID",
            key: "blogId",
            dataIndex: "blogId"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "Description",
            key: "description",
            render: (e) => e.shortDescription
        },
        {
            title: "Resim Grubu",
            key: "imageGroupID",
            render: (e) => e.imageGroup === null ? "" : e.imageGroup.title
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div>
                        <Button2 className='border-warning text-warning' icon={<Edit2 size={20} />} onClick={() => getData(e.blogId)} />
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu blog içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.blogId)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Blog Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button color='info' onClick={() => navigate("/image")}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Resim Grubu Ekle</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Blog Panosu Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input
                                            type='input'
                                            value={formData.title}
                                            onChange={e =>
                                                setFormData({ ...formData, title: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input'
                                            value={formData.titleEn}
                                            onChange={e =>
                                                setFormData({ ...formData, titleEn: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Resim Grubu</span>
                                        <Select
                                            options={imageGroups}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.imageGroupID}
                                            onChange={e => setFormData({ ...formData, imageGroupID: e })}
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input
                                            type='input'
                                            value={formData.desc}
                                            onChange={e =>
                                                setFormData({ ...formData, desc: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input
                                            type='input'
                                            value={formData.descEn}
                                            onChange={e =>
                                                setFormData({ ...formData, descEn: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>İçerik</span>
                                        <Input
                                            type='input'
                                            value={formData.content}
                                            onChange={e =>
                                                setFormData({ ...formData, content: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>İçerik (EN)</span>
                                        <Input
                                            type='input'
                                            value={formData.contentEn}
                                            onChange={e =>
                                                setFormData({ ...formData, contentEn: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Blog Panosu Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='blogId'
                                    dataSource={data}
                                    columns={columns}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default BlogPage;
