import React, { useEffect, useState } from 'react';
import { Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Select, Button as Button2, Upload } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { deleteBrand, getAllImageGroups, getAllBrands, getAllCategories, getProduct, getAllProducts, updateProduct, createProduct, createProductExcelImport } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';

const ProductPage = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [imageGroups, setImageGroups] = useState([]);
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [excel, setExcel] = useState(null)
    const [brands, setBrands] = useState([]);
    const [formData, setFormData] = useState({
        productName: "",
        productNameEN: "",
        shortDescription: "",
        shortDescriptionEN: "",
        content: "",
        contentEN: "",
        price: 0,
        discount: 0,
        discountedPrice: 0,
        additionalSpace1: "",
        additionalSpace1EN: "",
        additionalSpace2: "",
        additionalSpace2EN: "",
        additionalSpace3: "",
        additionalSpace3EN: "",
        barcodeNo: 0,
        lotNo: "",
        refNo: "",
        brandId: null,
        imageGroupID: null,
        categoryId: null,
    });

    const clearForm = () => {
        setFormData({ productName: "", productNameEN: "", shortDescription: "", shortDescriptionEN: "", content: "", contentEN: "", price: 0, discount: 0, discountedPrice: 0, additionalSpace1: "", additionalSpace1EN: "", additionalSpace2: "", additionalSpace2EN: "", additionalSpace3: "", additionalSpace3EN: "", barcodeNo: 0, lotNo: "", refNo: "", brandId: null, imageGroupID: null, categoryId: null })
        setSelectedData(null)
    }

    const addExcell = async () => {
        console.log(excel)
        const excelImport = await createProductExcelImport(excel);
        if (excelImport.message === "Success") {
            toast.success("Excel başarıyla içeri aktarıldı.")
        } else {
            toast.error("Excel içeri aktarılırken sorun oluştu!")
        }
        getDatas()
        setModal(false)
    }

    const getDatas = async () => {
        const imageGroups = await getAllImageGroups();
        const categories = await getAllCategories("");
        const brands = await getAllBrands("");
        const products = await getAllProducts("");
        if (products.message === "Success")
            setData(products.result);

        if (imageGroups.message === "Success") {
            var newlist = []
            for (var i = 0; i < imageGroups.result.length; i++) {
                if (imageGroups.result[i] !== undefined)
                    newlist.push({ value: imageGroups.result[i].imageGroupID, label: imageGroups.result[i].title });
            }
            setImageGroups(newlist);
        }
        if (categories.message === "Success") {
            var newlist = []
            for (var i = 0; i < categories.result.length; i++) {
                if (categories.result[i] !== undefined)
                    newlist.push({ value: categories.result[i].categoryId, label: categories.result[i].name });
            }
            setCategories(newlist);
        }
        if (brands.message === "Success") {
            var newlist = []
            for (var i = 0; i < brands.result.length; i++) {
                if (brands.result[i] !== undefined)
                    newlist.push({ value: brands.result[i].brandId, label: brands.result[i].brandName });
            }
            setBrands(newlist);
        }

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    };

    const getData = async (id) => {
        const brand = await getProduct(id);
        setSelectedData(id)
        setFormData({ productName: brand.result.productName, productNameEN: brand.result.productNameEN, shortDescription: brand.result.shortDescription, shortDescriptionEN: brand.result.shortDescriptionEN, content: brand.result.content, contentEN: brand.result.contentEN, price: brand.result.price, discount: brand.result.discount, discountedPrice: brand.result.discountedPrice, additionalSpace1: brand.result.additionalSpace1, additionalSpace1EN: brand.result.additionalSpace1EN, additionalSpace2: brand.result.additionalSpace2, additionalSpace2EN: brand.result.additionalSpace2EN, additionalSpace3: brand.result.additionalSpace3, additionalSpace3EN: brand.result.additionalSpace3EN, barcodeNo: brand.result.barcodeNo, lotNo: brand.result.lotNo, refNo: brand.result.refNo, brandId: brand.result.brandId, imageGroupID: brand.result.imageGroupID, categoryId: brand.result.categoryId, brandName: brand.result.brandName })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateProduct(selectedData, formData.productName, formData.productNameEN, formData.shortDescription, formData.shortDescriptionEN, formData.content, formData.contentEN, formData.price, formData.discount, formData.discountedPrice, formData.additionalSpace1, formData.additionalSpace1EN, formData.additionalSpace2, formData.additionalSpace2EN, formData.additionalSpace3, formData.additionalSpace3EN, formData.barcodeNo, formData.lotNo, formData.refNo, formData.brandId, formData.imageGroupID, formData.categoryId);
        } else {
            res = await createProduct(formData.productName, formData.productNameEN, formData.shortDescription, formData.shortDescriptionEN, formData.content, formData.contentEN, formData.price, formData.discount, formData.discountedPrice, formData.additionalSpace1, formData.additionalSpace1EN, formData.additionalSpace2, formData.additionalSpace2EN, formData.additionalSpace3, formData.additionalSpace3EN, formData.barcodeNo, formData.lotNo, formData.refNo, formData.brandId, formData.imageGroupID, formData.categoryId);
        }
        if (res.message === "Success")
            toast.success("Ürün içeriği eklendi.")
        else
            toast.error("Ürün içeriği eklenirken bir sorun oluştu!")
        getDatas()
        clearForm()
    }

    const deleteData = async (id) => {
        const brand = await deleteBrand(id);
        if (brand.message === "Success")
            toast.success("Ürün içeriği silindi.")
        else
            toast.error("Ürün içeriği silinirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "ID",
            key: "productId",
            dataIndex: "productId"
        },
        {
            title: "Başlık",
            key: "productName",
            render: (e) => e.productName
        },
        {
            title: "Description",
            key: "shortDescription",
            render: (e) => e.shortDescription
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div>
                        <Button2 className='border-warning text-warning' icon={<Edit2 size={20} />} onClick={() => getData(e.productId)} />
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu ürün içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.productId)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Ürün Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button color='info' className='ml-2' onClick={() => navigate("/image")}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Resim Grubu Ekle</Button>
                                <Button color='info' className='ml-2' onClick={() => navigate("/brand")}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Marka Ekle</Button>
                                <Button color='info' className='ml-2' onClick={() => navigate("/category")}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Kategori Ekle</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Ürün {selectedData ? "Güncelle" : "Ekle"}</h4></div>
                            </CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={4} className='mb-3'>
                                        <span>Ürün Adı</span>
                                        <Input
                                            type='input'
                                            value={formData.productName}
                                            onChange={e =>
                                                setFormData({ ...formData, productName: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ürün Adı (EN)</span>
                                        <Input type='input'
                                            value={formData.productNameEN}
                                            onChange={e =>
                                                setFormData({ ...formData, productNameEN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input
                                            type='input'
                                            value={formData.productName}
                                            onChange={e =>
                                                setFormData({ ...formData, productName: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input type='input'
                                            value={formData.shortDescriptionEN}
                                            onChange={e =>
                                                setFormData({ ...formData, shortDescriptionEN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>İçerik</span>
                                        <Input
                                            type='input'
                                            value={formData.content}
                                            onChange={e =>
                                                setFormData({ ...formData, content: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>İçerik (EN)</span>
                                        <Input type='input'
                                            value={formData.contentEN}
                                            onChange={e =>
                                                setFormData({ ...formData, contentEN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Fiyat</span>
                                        <Input
                                            type='number'
                                            value={formData.price}
                                            onChange={e =>
                                                setFormData({ ...formData, price: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>İndirim</span>
                                        <Input
                                            type='number'
                                            value={formData.discount}
                                            onChange={e =>
                                                setFormData({ ...formData, discount: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>İndirimli Fiyat</span>
                                        <Input
                                            disabled
                                            type='number'
                                            value={formData.discountedPrice}
                                            onChange={e =>
                                                setFormData({ ...formData, discountedPrice: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ek Alan 1</span>
                                        <Input
                                            type='input'
                                            value={formData.additionalSpace1}
                                            onChange={e =>
                                                setFormData({ ...formData, additionalSpace1: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ek Alan 1 (EN)</span>
                                        <Input
                                            type='input'
                                            value={formData.additionalSpace1EN}
                                            onChange={e =>
                                                setFormData({ ...formData, additionalSpace1EN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ek Alan 2</span>
                                        <Input
                                            type='input'
                                            value={formData.additionalSpace2}
                                            onChange={e =>
                                                setFormData({ ...formData, additionalSpace2: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ek Alan 2 (EN)</span>
                                        <Input
                                            type='input'
                                            value={formData.additionalSpace2EN}
                                            onChange={e =>
                                                setFormData({ ...formData, additionalSpace2EN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ek Alan 3</span>
                                        <Input
                                            type='input'
                                            value={formData.additionalSpace3}
                                            onChange={e =>
                                                setFormData({ ...formData, additionalSpace3: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ek Alan 3 (EN)</span>
                                        <Input
                                            type='input'
                                            value={formData.additionalSpace3EN}
                                            onChange={e =>
                                                setFormData({ ...formData, additionalSpace3EN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Barkod</span>
                                        <Input
                                            type='input'
                                            value={formData.barcodeNo}
                                            onChange={e =>
                                                setFormData({ ...formData, barcodeNo: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Lot No</span>
                                        <Input
                                            type='input'
                                            value={formData.lotNo}
                                            onChange={e =>
                                                setFormData({ ...formData, lotNo: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Ref No</span>
                                        <Input
                                            type='input'
                                            value={formData.refNo}
                                            onChange={e =>
                                                setFormData({ ...formData, refNo: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Marka</span>
                                        <Select
                                            options={brands}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.brandId}
                                            onChange={e => setFormData({ ...formData, brandId: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Resim Grubu</span>
                                        <Select
                                            options={imageGroups}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.imageGroupID}
                                            onChange={e => setFormData({ ...formData, imageGroupID: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Kategori</span>
                                        <Select
                                            options={categories}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.categoryId}
                                            onChange={e => setFormData({ ...formData, categoryId: e })}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Ürün Listesi</h4></div>
                                <div>
                                    <Button onClick={() => setModal(true)} className='border border-success bg-transparent text-success'><PlusCircle size={20} style={{ marginTop: -5, marginRight: 7 }} />Excel Import</Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='productId'
                                    dataSource={data}
                                    columns={columns}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setModal(!modal)}>Excel Import</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6}>
                            <Input
                                type='file'
                                onChange={(e) => setExcel(e.target.files[0])}
                            />
                        </Col>
                        <Col md={6}>
                            <Button className='w-100' color='success' onClick={() => addExcell()}>Ekle</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </motion.div >
    );
};

export default ProductPage;
