export const url2 = 'https://backendalkim.test.liberyus.com/api'

export const ADVERT_GETALL = `${url2}/Advert/GetAll`
export const ADVERT_GET = `${url2}/Advert/Get`
export const ADVERT_CREATE = `${url2}/Advert/Create`
export const ADVERT_UPDATE = `${url2}/Advert/Update`
export const ADVERT_DELETE = `${url2}/Advert/Delete`

export const AUTH_LOGIN = `${url2}/Authentication/Login`
export const AUTH_REGISTER = `${url2}/Authentication/Register`

export const BASKET_GETALL = `${url2}/Basket/GetAll`
export const BASKET_GET = `${url2}/Basket/Get`
export const BASKET_CREATE = `${url2}/Basket/Create`
export const BASKET_UPDATE = `${url2}/Basket/Update`
export const BASKET_DELETE = `${url2}/Basket/Delete`

export const BLOG_GETALL = `${url2}/Blog/GetAll`
export const BLOG_GET = `${url2}/Blog/Get`
export const BLOG_CREATE = `${url2}/Blog/Create`
export const BLOG_UPDATE = `${url2}/Blog/Update`
export const BLOG_DELETE = `${url2}/Blog/Delete`

export const BRAND_GETALL = `${url2}/Brand/GetAll`
export const BRAND_GET = `${url2}/Brand/Get`
export const BRAND_CREATE = `${url2}/Brand/Create`
export const BRAND_UPDATE = `${url2}/Brand/Update`
export const BRAND_DELETE = `${url2}/Brand/Delete`

export const BRANDCATEGORY_GETALL = `${url2}/BrandCategory/GetAll`
export const BRANDCATEGORY_GET = `${url2}/BrandCategory/Get`
export const BRANDCATEGORY_CREATE = `${url2}/BrandCategory/Create`
export const BRANDCATEGORY_UPDATE = `${url2}/BrandCategory/Update`
export const BRANDCATEGORY_DELETE = `${url2}/BrandCategory/Delete`

export const BOOKCASE_GETALL = `${url2}/BookCase/GetAll`
export const BOOKCASE_GET = `${url2}/BookCase/Get`
export const BOOKCASE_CREATE = `${url2}/BookCase/Create`
export const BOOKCASE_DELETE = `${url2}/BookCase/Delete`

export const CATEGORY_GETALL = `${url2}/Category/GetAll`
export const CATEGORY_GET = `${url2}/Category/Get`
export const CATEGORY_CREATE = `${url2}/Category/Create`
export const CATEGORY_UPDATE = `${url2}/Category/Update`
export const CATEGORY_DELETE = `${url2}/Category/Delete`

export const CONTACT_GETALL = `${url2}/Contact/GetAll`
export const CONTACT_GET = `${url2}/Contact/Get`
export const CONTACT_CREATE = `${url2}/Contact/Create`
export const CONTACT_UPDATE = `${url2}/Contact/Update`
export const CONTACT_DELETE = `${url2}/Contact/Delete`

export const FILE_GETALL = `${url2}/File/GetAll`
export const FILE_GET = `${url2}/File/Get`
export const FILE_CREATE = `${url2}/File/Create`
export const FILE_UPDATE = `${url2}/File/Update`
export const FILE_DELETE = `${url2}/File/Delete`

export const FOLDER_GETALL = `${url2}/Folder/GetAll`
export const FOLDER_GET = `${url2}/Folder/Get`
export const FOLDER_CREATE = `${url2}/Folder/Create`
export const FOLDER_UPDATE = `${url2}/Folder/Update`
export const FOLDER_DELETE = `${url2}/Folder/Delete`

export const FOOTER_GETALL = `${url2}/Footer/GetAll`
export const FOOTER_GET = `${url2}/Footer/Get`
export const FOOTER_CREATE = `${url2}/Footer/Create`
export const FOOTER_UPDATE = `${url2}/Footer/Update`
export const FOOTER_DELETE = `${url2}/Footer/Delete`

export const FAVORITE_GETALL = `${url2}/Favorite/GetAll`
export const FAVORITE_GET = `${url2}/Favorite/Get`
export const FAVORITE_CREATE = `${url2}/Favorite/Create`
export const FAVORITE_DELETE = `${url2}/Favorite/Delete`

export const FORM_GETALL = `${url2}/Form/GetAll`
export const FORM_GET = `${url2}/Form/Get`
export const FORM_CREATE = `${url2}/Form/Create`
export const FORM_UPDATE = `${url2}/Form/Update`
export const FORM_DELETE = `${url2}/Form/Delete`

export const FORMCATEGORY_GETALL = `${url2}/FormCategory/GetAll`
export const FORMCATEGORY_GET = `${url2}/FormCategory/Get`
export const FORMCATEGORY_CREATE = `${url2}/FormCategory/Create`
export const FORMCATEGORY_UPDATE = `${url2}/FormCategory/Update`
export const FORMCATEGORY_DELETE = `${url2}/FormCategory/Delete`

export const FORMELEMENT_GETALL = `${url2}/FormElement/GetAll`
export const FORMELEMENT_GET = `${url2}/FormElement/Get`
export const FORMELEMENT_CREATE = `${url2}/FormElement/Create`
export const FORMELEMENT_UPDATE = `${url2}/FormElement/Update`
export const FORMELEMENT_DELETE = `${url2}/FormElement/Delete`

export const FORMRESPONSE_GETALL = `${url2}/FormResponse/GetAll`
export const FORMRESPONSE_GET = `${url2}/FormResponse/Get`
export const FORMRESPONSE_CREATE = `${url2}/FormResponse/Create`
export const FORMRESPONSE_DELETE = `${url2}/FormResponse/Delete`

export const FORMRETURN_GETALL = `${url2}/FormReturn/GetAll`
export const FORMRETURN_GETALLBYCLIENT = `${url2}/FormReturn/GetAllByClient`
export const FORMRETURN_GET = `${url2}/FormReturn/Get`
export const FORMRETURN_CREATE = `${url2}/FormReturn/Create`
export const FORMRETURN_DELETE = `${url2}/FormReturn/Delete`

export const HEADER_GETALL = `${url2}/Header/GetAll`
export const HEADER_GET = `${url2}/Header/Get`
export const HEADER_CREATE = `${url2}/Header/Create`
export const HEADER_UPDATE = `${url2}/Header/Update`
export const HEADER_DELETE = `${url2}/Header/Delete`

export const IMAGE_GETALL = `${url2}/Image/GetAll`
export const IMAGE_GETALLBYID = `${url2}/Image/GetAllById`
export const IMAGE_GET = `${url2}/Image/Get`
export const IMAGE_CREATE = `${url2}/Image/Create`
export const IMAGE_UPDATE = `${url2}/Image/Update`
export const IMAGE_DELETE = `${url2}/Image/Delete`

export const IMAGEGROUP_GETALL = `${url2}/ImageGroup/GetAll`
export const IMAGEGROUP_GET = `${url2}/ImageGroup/Get`
export const IMAGEGROUP_CREATE = `${url2}/ImageGroup/Create`
export const IMAGEGROUP_UPDATE = `${url2}/ImageGroup/Update`
export const IMAGEGROUP_DELETE = `${url2}/ImageGroup/Delete`

export const KID_GETALL = `${url2}/Kid/GetAll`
export const KID_GETALLBYMOM = `${url2}/Kid/GetAllByMom`
export const KID_GET = `${url2}/Kid/Get`
export const KID_CREATE = `${url2}/Kid/Create`
export const KID_UPDATE = `${url2}/Kid/Update`
export const KID_DELETE = `${url2}/Kid/Delete`

export const MEDIA_GETALL = `${url2}/Media/GetAll`
export const MEDIA_GET = `${url2}/Media/Get`
export const MEDIA_CREATE = `${url2}/Media/Create`
export const MEDIA_UPDATE = `${url2}/Media/Update`
export const MEDIA_DELETE = `${url2}/Media/Delete`

export const PAGE_GETALL = `${url2}/Page/GetAll`
export const PAGE_GET = `${url2}/Page/Get`
export const PAGE_CREATE = `${url2}/Page/Create`
export const PAGE_UPDATE = `${url2}/Page/Update`
export const PAGE_DELETE = `${url2}/Page/Delete`

export const POPUP_GETALL = `${url2}/Popup/GetAll`
export const POPUP_GET = `${url2}/Popup/Get`
export const POPUP_CREATE = `${url2}/Popup/Create`
export const POPUP_UPDATE = `${url2}/Popup/Update`
export const POPUP_DELETE = `${url2}/Popup/Delete`

export const PRODUCT_GETALL = `${url2}/Product/GetAll`
export const PRODUCT_GET = `${url2}/Product/Get`
export const PRODUCT_CREATE = `${url2}/Product/Create`
export const PRODUCT_EXCELIMPORT = `${url2}/Product/ExcelImport`
export const PRODUCT_UPDATE = `${url2}/Product/Update`
export const PRODUCT_DELETE = `${url2}/Product/Delete`

export const PRODUCTCATEGORY_GETALL = `${url2}/ProductCategory/GetAll`
export const PRODUCTCATEGORY_GET = `${url2}/ProductCategory/Get`
export const PRODUCTCATEGORY_CREATE = `${url2}/ProductCategory/Create`
export const PRODUCTCATEGORY_UPDATE = `${url2}/ProductCategory/Update`
export const PRODUCTCATEGORY_DELETE = `${url2}/ProductCategory/Delete`

export const PROMOTION_GETALL = `${url2}/Promotion/GetAll`
export const PROMOTION_GET = `${url2}/Promotion/Get`
export const PROMOTION_CREATE = `${url2}/Promotion/Create`
export const PROMOTION_UPDATE = `${url2}/Promotion/Update`
export const PROMOTION_DELETE = `${url2}/Promotion/Delete`

export const SETTING_GETALL = `${url2}/Setting/GetAll`
export const SETTING_GET = `${url2}/Setting/Get`
export const SETTING_CREATE = `${url2}/Setting/Create`
export const SETTING_UPDATE = `${url2}/Setting/Update`
export const SETTING_DELETE = `${url2}/Setting/Delete`

export const SEO_GETALL = `${url2}/Seo/GetAll`
export const SEO_GET = `${url2}/Seo/Get`
export const SEO_CREATE = `${url2}/Seo/Create`
export const SEO_UPDATE = `${url2}/Seo/Update`
export const SEO_DELETE = `${url2}/Seo/Delete`

export const SIDEBAR_GETALL = `${url2}/Sidebar/GetAll`
export const SIDEBAR_GET = `${url2}/Sidebar/Get`
export const SIDEBAR_CREATE = `${url2}/Sidebar/Create`
export const SIDEBAR_UPDATE = `${url2}/Sidebar/Update`
export const SIDEBAR_DELETE = `${url2}/Sidebar/Delete`

export const SLIDER_GETALL = `${url2}/Slider/GetAll`
export const SLIDER_GET = `${url2}/Slider/Get`
export const SLIDER_CREATE = `${url2}/Slider/Create`
export const SLIDER_UPDATE = `${url2}/Slider/Update`
export const SLIDER_DELETE = `${url2}/Slider/Delete`

export const SLIDERGROUP_GETALL = `${url2}/SliderGroup/GetAll`
export const SLIDERGROUP_GET = `${url2}/SliderGroup/Get`
export const SLIDERGROUP_CREATE = `${url2}/SliderGroup/Create`
export const SLIDERGROUP_UPDATE = `${url2}/SliderGroup/Update`
export const SLIDERGROUP_DELETE = `${url2}/SliderGroup/Delete`

export const SSS_GETALL = `${url2}/SSS/GetAll`
export const SSS_GET = `${url2}/SSS/Get`
export const SSS_CREATE = `${url2}/SSS/Create`
export const SSS_UPDATE = `${url2}/SSS/Update`
export const SSS_DELETE = `${url2}/SSS/Delete`

export const SOCIALMEDIA_GETALL = `${url2}/SocialMedia/GetAll`
export const SOCIALMEDIA_GET = `${url2}/SocialMedia/Get`
export const SOCIALMEDIA_CREATE = `${url2}/SocialMedia/Create`
export const SOCIALMEDIA_UPDATE = `${url2}/SocialMedia/Update`
export const SOCIALMEDIA_DELETE = `${url2}/SocialMedia/Delete`

export const SURVEY_GETALL = `${url2}/Survey/GetAll`
export const SURVEY_GET = `${url2}/Survey/Get`
export const SURVEY_CREATE = `${url2}/Survey/Create`
export const SURVEY_UPDATE = `${url2}/Survey/Update`
export const SURVEY_DELETE = `${url2}/Survey/Delete`

export const SURVEYELEMENT_GETALL = `${url2}/SurveyElement/GetAll`
export const SURVEYELEMENT_GET = `${url2}/SurveyElement/Get`
export const SURVEYELEMENT_CREATE = `${url2}/SurveyElement/Create`
export const SURVEYELEMENT_UPDATE = `${url2}/SurveyElement/Update`
export const SURVEYELEMENT_DELETE = `${url2}/SurveyElement/Delete`

export const SURVEYRESPONSE_GETALL = `${url2}/SurveyResponse/GetAll`
export const SURVEYRESPONSE_GET = `${url2}/SurveyResponse/Get`
export const SURVEYRESPONSE_CREATE = `${url2}/SurveyResponse/Create`
export const SURVEYRESPONSE_UPDATE = `${url2}/SurveyResponse/Update`
export const SURVEYRESPONSE_DELETE = `${url2}/SurveyResponse/Delete`

export const UNIT_GETALL = `${url2}/Unit/GetAll`
export const UNIT_GET = `${url2}/Unit/Get`
export const UNIT_CREATE = `${url2}/Unit/Create`
export const UNIT_UPDATE = `${url2}/Unit/Update`
export const UNIT_DELETE = `${url2}/Unit/Delete`

export const UNPUBLICFILE_GETALL = `${url2}/UnPublicFile/GetAll`
export const UNPUBLICFILE_GET = `${url2}/UnPublicFile/Get`
export const UNPUBLICFILE_CREATE = `${url2}/UnPublicFile/Create`
export const UNPUBLICFILE_UPDATE = `${url2}/UnPublicFile/Update`
export const UNPUBLICFILE_DELETE = `${url2}/UnPublicFile/Delete`

export const UNPUBLICFILEGROUP_GETALL = `${url2}/UnPublicFileGroup/GetAll`
export const UNPUBLICFILEGROUP_GET = `${url2}/UnPublicFileGroup/Get`
export const UNPUBLICFILEGROUP_CREATE = `${url2}/UnPublicFileGroup/Create`
export const UNPUBLICFILEGROUP_UPDATE = `${url2}/UnPublicFileGroup/Update`
export const UNPUBLICFILEGROUP_DELETE = `${url2}/UnPublicFileGroup/Delete`

export const USER_GETALL = `${url2}/User/GetAll`
export const USER_GET = `${url2}/User/Get`
export const USER_UPDATE = `${url2}/User/Update`
export const USER_DELETE = `${url2}/User/Delete`
export const USER_CHANGEPASSWORD = `${url2}/User/ChangePassword`
export const USER_POINTCHANGE = `${url2}/User/PointChange`